/* @import "tailwindcss/base";
 * @import "tailwindcss/components";
 * @import "tailwindcss/utilities";
 */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply px-4 py-2 bg-primary text-white rounded-lg hover:bg-opacity-90 transition-all;
  }
  .btn-secondary {
    @apply px-4 py-2 bg-secondary text-white rounded-lg hover:bg-opacity-90 transition-all;
  }
  .card {
    @apply bg-white rounded-lg shadow-md p-6;
  }
}

@layer utilities {
  .aspect-w-3 {
    position: relative;
    padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  }
  .aspect-h-2 {
    --tw-aspect-h: 2;
  }
  .aspect-w-3 {
    --tw-aspect-w: 3;
  }
  .aspect-w-3 > * {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
