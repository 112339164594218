body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes ticker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-ticker {
  animation: ticker 45s linear infinite;
  will-change: transform;
}

/* Smoother animation on hover */
.animate-ticker:hover {
  animation-play-state: paused;
  transition: all 0.3s ease;
}

/* Add gradient fade effects */
.ticker-container {
  position: relative;
}

.ticker-container::before,
.ticker-container::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
  z-index: 2;
  pointer-events: none;
}

.ticker-container::before {
  left: 0;
  background: linear-gradient(to right, #F5F5DC 0%, transparent 100%);
}

.ticker-container::after {
  right: 0;
  background: linear-gradient(to left, #F5F5DC 0%, transparent 100%);
}

.page-container {
  margin-top: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  flex: 1;
}
